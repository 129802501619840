import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  addDoc,
  deleteDoc,
  Timestamp,
  writeBatch,
  where,
  query,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { omit } from 'lodash';
import { CAMPAIGNS } from '../../config';
import { FirestoreInstance, FirebaseFunctions } from '../../contexts/Firebase';
import { dateTransformer, dropNullUndefined, removeEmpty } from '../../utils/transformers';
import { defaultSchedule } from '../../contexts/defaults/schedule';
import { initOuterSequence } from '../../contexts/defaults/sequence';
import { getUser } from './usersClients';
import { roundCampaignPercentages } from '../../utils/percentages';

export const toggleAccountInControl = httpsCallable(FirebaseFunctions, 'updateWarmupControl');
export const campaignsNotSending = httpsCallable(FirebaseFunctions, 'campaignsNotSending');

export const getCampaign = async ({ id }) => {
  const campaignRef = doc(FirestoreInstance, `campaigns`, id);
  const resp = await getDoc(campaignRef);
  return { id: resp.id, ...resp.data() };
};

const prepareProspectForCampaign = (prospect) => ({
  email: prospect.email,
  status: 'bucket',
  info: removeEmpty(omit(prospect, ['id', 'selected', 'editable'])),
  leadStatus: 'none',
});

export const addPeopleToCampaignCallable = httpsCallable(FirebaseFunctions, 'addPeopleToCampaign');
const onLaunchCampaign = httpsCallable(FirebaseFunctions, 'onLaunchCampaign');
export const importPeopleFromGoogleSheet = httpsCallable(FirebaseFunctions, 'importPeopleFromGoogleSheet');

export const pauseAllCampaigns = httpsCallable(FirebaseFunctions, 'pauseAllCampaigns');

export const addPeopleToCampaign = async ({ bucket, campaignId, clientId, user }) => {
  const people = Object.values(bucket).map((prospect) => prepareProspectForCampaign(prospect));

  try {
    await addPeopleToCampaignCallable({ people, campaignId, user, clientId });
  } catch (err) {
    console.error(err);
    throw new Error(err.message);
  }
};

export const addCampaignToUser = async ({ campaign, user }) => {
  const uid = user?.uid || user.orgId;
  const now = Timestamp.now();

  const colRef = collection(FirestoreInstance, 'campaigns');

  const newCampaign = {
    ...dropNullUndefined(omit(campaign, ['conditions'])),
    status: CAMPAIGNS.STATUS.draft.key,
    schedule: defaultSchedule,
    sequence: initOuterSequence(),
    createdBy: uid,
    createdAt: now,
  };
  const cmpgn = await addDoc(colRef, newCampaign);

  // Reprioritize:
  const cli = campaign?.client;
  const client = await getUser(cli);
  if (client.campaignPriority) {
    const clientRef = doc(FirestoreInstance, 'users', cli);
    const nuPriorityMap = roundCampaignPercentages(client.campaignPriority, cmpgn.id, client.campaigns);
    await updateDoc(clientRef, { campaignPriority: nuPriorityMap, modifiedAt: now, campaigns: arrayUnion(cmpgn.id) });
  }

  const userRef = doc(FirestoreInstance, 'users', uid);

  const campaignUpdate = {
    campaigns: arrayUnion(cmpgn.id),
    modifiedAt: now,
  };

  await updateDoc(userRef, campaignUpdate);

  return cmpgn;
};

export const editCampaign = async ({ id, campaign }) => {
  const now = Timestamp.now();

  const campRef = doc(FirestoreInstance, 'campaigns', id);
  const editCampaign = await updateDoc(campRef, {
    ...campaign,
    modifiedAt: now,
  });

  return editCampaign;
};

export const editProspect = async ({ campaignId, prospectId, prospectData }) => {
  const now = Timestamp.now();

  const prospectRef = doc(FirestoreInstance, `/campaigns/${campaignId}/people`, prospectId);
  const editProspect = await updateDoc(prospectRef, {
    ...prospectData,
    modifiedAt: now,
  });

  return editProspect;
};

export const editCampaignSequence = async ({ sequence, id }) => {
  const now = Timestamp.now();

  const seqRef = doc(FirestoreInstance, 'campaigns', id);
  const editCampaign = await updateDoc(seqRef, {
    sequence: {
      ...sequence,
      modifiedAt: now,
      touched: true,
    },
  });

  return editCampaign;
};

export const editCampaignSchedule = async ({ schedule, id }) => {
  const now = Timestamp.now();

  const seqRef = doc(FirestoreInstance, 'campaigns', id);
  const editCampaign = await updateDoc(seqRef, {
    schedule: {
      ...schedule,
      modifiedAt: now,
      touched: true,
    },
  });

  return editCampaign;
};

export const editCampaignOptions = async ({ options, accounts, nextBatch, id }) => {
  const now = Timestamp.now();

  const cmpgnRef = doc(FirestoreInstance, 'campaigns', id);

  const update = {
    options,
    accounts,
    modifiedAt: now,
  };

  if (nextBatch) {
    const nextBatchDate = dateTransformer(nextBatch);
    const timestamp = Timestamp.fromDate(nextBatchDate);
    update.nextBatch = timestamp;
  }

  await updateDoc(cmpgnRef, update);

  const editCampaign = await getDoc(cmpgnRef);

  return editCampaign;
};
export const addJobsToCampaign = async ({ id, jobs }) => {
  /* eslint-disable */
  const jobsRef = collection(FirestoreInstance, 'campaigns', id, 'jobs');
  for (const job of Object.values(jobs)) {
    await addDoc(jobsRef, job);
  }
  /* eslint-enable */
};

export const changeCampaignStatus = async ({ id, status, batch }) => {
  const now = Timestamp.now();
  const campaignRef = doc(FirestoreInstance, 'campaigns', id);
  const updater = {
    status,
    [`${status}At`]: now,
    modifiedAt: now,
  };

  if (batch) {
    batch.update(campaignRef, updater);
    return batch;
  }
  await updateDoc(campaignRef, updater);

  const cmpgn = await getDoc(campaignRef);
  return cmpgn;
};

export const campaignRunner = httpsCallable(FirebaseFunctions, 'campaignRunner');

const deleteCampaignSubCollections = httpsCallable(FirebaseFunctions, 'deleteCampaignSubCollections');
const deleteFieldsInCampaign = httpsCallable(FirebaseFunctions, 'deleteFieldsInCampaign');

export const deleteCampaign = (id) => {
  const campaignRef = doc(FirestoreInstance, 'campaigns', id);
  return deleteDoc(campaignRef);
};

export const restartCampaign = async (id) => {
  const promises = [];

  promises.push(deleteCampaignSubCollections(id));
  promises.push(
    deleteFieldsInCampaign({
      id,
      fields: [
        'pausedAt',
        'readyAt',
        'runningAt',
        'draftAt',
        'next',
        'nextBatch',
        'control',
        'sent',
        'hasJobs',
        'people',
        'journeys',
        'jobs',
        'options.bucketDripLeftToday',
      ],
    })
  );

  promises.push(changeCampaignStatus({ id, status: CAMPAIGNS.STATUS.draft.key }));

  const deletePeople = () => {
    const campaignRef = doc(FirestoreInstance, 'campaigns', id);
    return updateDoc(campaignRef, { people: [] });
  };

  promises.push(deletePeople());

  await Promise.all(promises);

  return null;
};

export const launchCampaign = async ({ id, uid, campaign, conditions }) => {
  Object.entries(CAMPAIGNS.CONDITIONS).forEach(([key, cond]) => {
    if (!conditions[key]) throw new Error(cond.error);
  });

  const batch = writeBatch(FirestoreInstance);
  await launchThisCampaign({ id, campaign, batch, uid });
  await changeCampaignStatus({ id, status: CAMPAIGNS.STATUS.running.key, batch });

  await batch.commit();

  try {
    await onLaunchCampaign(id);
  } catch (err) {
    console.error(err);
    await changeCampaignStatus({ id, status: CAMPAIGNS.STATUS.draft.key });
    throw new Error(`There was an error launching the campaign. Please open a support ticket: ${err.message}`);
  }

  return true;
};

const launchThisCampaign = async ({ id, campaign, batch }) => {
  const now = Timestamp.now();
  const control = {
    modifiedAt: now,
    startedAt: now,
  };

  if (campaign?.status && !campaign?.launchedAt) {
    control.sent = 0;
  }
  if (campaign?.status && !campaign?.launchedAt) {
    control.sent = 0;
  }

  const campaignRef = doc(FirestoreInstance, 'campaigns', id);

  if (batch) {
    batch.update(campaignRef, control);
    return batch;
  }
  return updateDoc(campaignRef, control);
};

export const addNextBatchOfProspects = httpsCallable(FirebaseFunctions, 'addNextBatchOfProspects');
export const addImmediateBatch = httpsCallable(FirebaseFunctions, 'addImmediateBatch');
export const clientrunner = httpsCallable(FirebaseFunctions, 'clientrunner');

export const pauseCampaign = (id) => changeCampaignStatus({ id, status: CAMPAIGNS.STATUS.paused.key });

export const deleteProspects = async ({ campaignId, prospects, deleteActivities = false }) => {
  const batch = writeBatch(FirestoreInstance);
  const campaignRef = doc(FirestoreInstance, 'campaigns', campaignId);
  const campaign = await getDoc(campaignRef);

  await Promise.all(
    prospects?.map((prospect) => {
      batch.delete(doc(FirestoreInstance, 'campaigns', campaignId, 'people', prospect));
      if (campaign.people && campaign.people.includes(prospect))
        batch.set(campaignRef, { people: arrayRemove(prospect) });

      if (deleteActivities) {
        const activities = query(
          collection(FirestoreInstance, 'campaigns', campaignId, 'activities'),
          where('data.prospectId', '==', prospect)
        );

        return getDocs(activities).then((snapshot) => snapshot.docs.forEach((doc) => batch.delete(doc.ref)));
      }

      return false;
    })
  );
  await batch.commit();
  return true;
};

export const unsubscribe = httpsCallable(FirebaseFunctions, 'unsubscribe');

export const reScore = httpsCallable(FirebaseFunctions, 'reScoreActivity');

export const setGoogleSheetMap = async ({ columnMap, googleSpreadsheetId, googleSheetId, campaignId }) => {
  // turn column map into an object
  const map = {};
  columnMap.forEach((mapItem) => {
    map[mapItem.column] = mapItem.field;
  });

  const ref = doc(FirestoreInstance, `/campaigns/${campaignId}/`);

  await updateDoc(ref, {
    googleSheets: {
      spreadsheetId: googleSpreadsheetId,
      sheetId: googleSheetId,
      map,
    },
  });
};
