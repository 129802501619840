import { addDoc, collection, getDoc } from 'firebase/firestore';
import { FirestoreInstance } from '../../contexts/Firebase';
import { initializeStripe } from '../../contexts/Stripe';

/* eslint-disable */
function getClientReferenceId() {
  return (window.Rewardful && window.Rewardful.referral) || 'checkout_' + new Date().getTime();
}
/* eslint-enable */

const createCheckoutSession = async ({ price, uid }) => {
  const checkoutSessionObject = {
    price,
    success_url: `${process.env.REACT_APP_URL}/dashboard/back_from_stripe`,
    cancel_url: `${process.env.REACT_APP_URL}/dashboard/cancelled_stripe`,
    allow_promotion_codes: true,
    client_reference_id: getClientReferenceId(),

    // TRY
    automatic_tax: false, // Automatically calculate tax based on the customer's address
    tax_id_collection: true, // Collect the customer's tax ID (important for B2B transactions)
  };

  const checkoutSessionRef = collection(FirestoreInstance, `/users/${uid}/checkout_sessions`);
  const myDoc = await addDoc(checkoutSessionRef, checkoutSessionObject);

  // wait 5 seconds
  await new Promise((resolve) => setTimeout(resolve, 5000));

  let doc = await getDoc(myDoc);

  if (!doc) {
    await new Promise((resolve) => setTimeout(resolve, 3000));
    doc = await getDoc(myDoc);
  }

  const { sessionId } = doc.data();
  if (sessionId) {
    const stripe = await initializeStripe();
    await stripe.redirectToCheckout({ sessionId });
  }
  let { url } = doc.data();

  if (!url) {
    await new Promise((resolve) => setTimeout(resolve, 3000));
    const urlData = doc.data();
    url = urlData;
  }
  if (url) {
    window.location.href = url;
  }
};

export default createCheckoutSession;
